import { ß, breakpoints } from "./utils";

export default function() {
  const filter = document.querySelectorAll(`.filter`);

  const updateSameValue = (module, target) => {
    const { value } = target.dataset;
    const valueSiblings = module.querySelectorAll(
      `input[data-value="${value}"]`
    );
    for (const sib of valueSiblings) {
      sib.checked = target.checked;
    }
  };

  const collectActiveTags = module => target => {
    if (target) {
      updateSameValue(module, target);
    }
    const boxes = module.querySelectorAll(`input:checked`);
    if (boxes.length) {
      window.search.activeTags.clear();
      const splitFilters = Array.from(boxes).map(input =>
        input.dataset.value.split(`;`).filter(a => a !== ``)
      );
      const flat = splitFilters.flat();
      window.search.addTags(flat);
    } else {
      window.search.clearTags();
    }
  };

  function generateHTMLforCheckbox(value, name) {
    return `<div class="checkbox">
            <label class="container">
              ${name}
              <input class="checkbox-input" type="checkbox" data-value="${value}">
              <span class="checkmark icon-check"></span>
            </label>
          </div>`;
  }

  function initEventHandling(checkbox, handler) {
    const input = checkbox.querySelector(`input`);
    // handler(input);
    input.onclick = event => {
      const { target } = event;
      handler(target);
    };
  }

  const markActive = filterBox => () => {
    window.requestAnimationFrame(() => {
      const checkboxes = filterBox.querySelectorAll(`input[data-value]`);
      for (const box of checkboxes) {
        const boxFilter = box.dataset.value.split(`;`).filter(a => a !== ``);
        let willBeChecked = true;
        for (const filterValue of boxFilter) {
          if (!window.search.activeTags.has(filterValue)) {
            willBeChecked = false;
            break;
          }
        }

        if (willBeChecked) box.checked = true;
      }
    });
  };

  const setContent = filterBox => obj => {
    // Delete previous generated:
    for (const generated of filterBox.querySelectorAll(`div[generated]`)) {
      generated.parentNode.removeChild(generated);
    }

    const keys = Object.keys(obj).sort();
    for (const category of keys) {
      const items = obj[category].sort((a, b) => {
        if (a.get(`name`) < b.get(`name`)) return -1;
        if (a.get(`name`) > b.get(`name`)) return 1;
        return 0;
      });
      const pre = `<div class="form-block" generated data-filter="${category}"><h2>${category}</h2><div class="check-container">`;
      const post = `</div>`;
      const html = items.length
        ? [
            pre,
            ...items
              // .filter(item => item.get(`root`) !== item.get(`name`))
              .reduce(
                (accu, item) =>
                  accu.add(
                    generateHTMLforCheckbox(item.get(`full`), item.get(`name`))
                  ),
                new Set()
              ),
            post
          ]
        : `<span class="warn">Keine Filter verfügbar</span>`;
      filterBox.insertAdjacentHTML(`beforeend`, html.join(`\n`));
      const checkboxes = filterBox.querySelectorAll(`.checkbox`);
      for (const checkbox of checkboxes) {
        initEventHandling(checkbox, collectActiveTags(filterBox));
      }
    }
    markActive(filterBox)(window.search.activeTags);
  };

  function initVisuals(module, contentMaxHeight = 4000) {
    const content = ß(`.content`, module);
    const head = ß(`.header`, module);
    const show = ß(`.show`, module);
    show.addEventListener(`click`, () => {
      if (head.toggle(`active`)) {
        content.style.maxHeight = `${contentMaxHeight - 44}px`;
      } else {
        content.style.maxHeight = `0px`;
      }
    });
  }

  const updateVisuals = module => contentMaxHeight => {
    const content = ß(`.content`, module);
    content.style.maxHeight = `${contentMaxHeight - 44}px`;
  };

  function init(module) {
    const checkboxes = module.querySelectorAll(`.checkbox`);
    const parentNode = module.parentElement;
    // lockPredefinedFilter(module)();
    for (const checkbox of checkboxes) {
      initEventHandling(checkbox, collectActiveTags(module));
    }
    collectActiveTags(module)();

    const contentMaxHeight =
      module.closest(`.split`) && breakpoints.extralarge()
        ? window.getComputedStyle(parentNode).height - 42
        : 20000;

    if (module.closest(`.split`)) {
      const upVis = updateVisuals(module);
      const observer = new window.MutationObserver(mutationlist => {
        const { target } = mutationlist[0];
        if (target.style.height !== ``) {
          const height = target.style.height.substr(
            0,
            target.style.height.length - 2
          );
          upVis(height);
        }
      });
      observer.observe(parentNode, {
        attributes: true,
        attributeFilter: [`style`]
      });
    }
    initVisuals(module, contentMaxHeight);
    const filters = module.querySelector(`.content form`);
    if (module.dataset.autogenerated) {
      window.search.subscribeToAvailableTags(setContent(filters));
      window.search.subscribeToTags(markActive(filters));
    }
  }

  if (filter.length > 2) {
    console.log(`only one filter per page allowed`); // eslint-disable-line no-console
  } else if (filter.length !== 0) {
    init(filter[0]);
  }
}
