/* eslint-disable class-methods-use-this */
import { h, Component } from "preact";

export default class MediumTGItem extends Component {
  render(props) {
    return (
      <a
        href={props.href}
        className="item item-1-3-text"
        style={`animation-delay: ${props.animationDelay}ms`}
      >
        <div className="visual">
          <img src={props.thumbnail} />
        </div>
        <div className="caption">{props.title}</div>
        <div className="text-box">
          <h3 className="caption-big">{props.title}</h3>
          <span className="intro-text">{props.introtext}</span>
          <p>{props.teaser}</p>
        </div>
      </a>
    );
  }
}
