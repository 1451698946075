export default function() {
  const modules = document.querySelectorAll(`.navigation-container`);

  const setSearchQuery = searchInput => () => {
    if (!window.searchPage) {
      // eslint-disable-next-line no-console
      console.warn(`Please define a search page in the Document Properties`);
    }
    if (window.location.pathname === window.searchPage) {
      window.search.setQuery(searchInput.value);
    } else {
      window.location.assign(`
        ${window.location.origin}${window.searchPage ||
        window.location.pathname}?query=${searchInput.value}&tags=&company=;
      `);
    }
  };

  function initSearchSubmit(module) {
    const searchForm = module.querySelector(`.search-form`);
    const searchInput = searchForm.querySelector(`input[name="search"]`);
    if (searchForm) {
      searchForm.onsubmit = setSearchQuery(searchInput);
    }
  }

  function detectTopScoll(module) {
    window.addEventListener(`scroll`, () => {
      const doc = document.documentElement;
      if (doc.scrollTop === 0) {
        module.parentElement.classList.remove(`scroll`);
      } else {
        module.parentElement.classList.add(`scroll`);
      }
    });
  }

  function toogleNavigationDesktop(module, item) {
    const target = item.target.closest(`li`);
    const isLogin = item.target.closest(`.login`) !== null;
    const isOpen = target.classList.contains(`open`);
    const openLevel = module.querySelector(`.first-level-item.open`);
    if (openLevel !== null && !isLogin) {
      openLevel.classList.remove(`open`);
    }
    if (!isOpen) {
      target.classList.add(`open`);
    }
  }

  function initDesktop(module) {
    const searchIcon = module.querySelector(`.search-icon`);
    const search = module.querySelector(`.search`);
    const firstLvltems = module.querySelectorAll(`.first-level-item`);
    searchIcon.addEventListener(`click`, () => {
      search.style.display = `block`;
    });
    for (const firstItem of firstLvltems) {
      firstItem.addEventListener(`click`, item => {
        toogleNavigationDesktop(module, item);
      });
    }
    detectTopScoll(module);
    initSearchSubmit(module);
  }

  function toggleNavigation(event) {
    event.target.closest(`.navigation`).classList.toggle(`open`);
  }

  function openSearch(searchContainer, searchItem) {
    const loginContainer = searchContainer.parentElement.querySelector(
      `.login`
    );
    const loginItem = searchItem
      .closest(`.menu-popup`)
      .querySelector(`.login-item`);
    closeLogin(loginContainer, loginItem); // eslint-disable-line no-use-before-define
    searchContainer.classList.add(`open`);
    searchItem.classList.add(`open`);
  }

  function closeSearch(searchContainer, searchItem) {
    searchContainer.classList.remove(`open`);
    searchItem.classList.remove(`open`);
  }

  function toggleSearch(event) {
    const searchContainer = event.target.closest(`.search`);
    const searchItem = searchContainer
      .closest(`.navigation`)
      .querySelector(`.search-item`);
    if (searchContainer.classList.contains(`open`)) {
      closeSearch(searchContainer, searchItem);
    } else {
      openSearch(searchContainer, searchItem);
    }
  }

  function openLogin(loginContainer, loginItem) {
    const searchContainer = loginContainer.parentElement.querySelector(
      `.search`
    );
    const searchItem = loginItem
      .closest(`.menu-popup`)
      .querySelector(`.search-item`);
    closeSearch(searchContainer, searchItem);
    loginContainer.classList.add(`open`);
    loginItem.classList.add(`open`);
  }

  function closeLogin(loginContainer, loginItem) {
    loginContainer.classList.remove(`open`);
    loginItem.classList.remove(`open`);
  }

  function toggleLogin(event) {
    const loginContainer = event.target.closest(`.login`);
    const loginItem = loginContainer
      .closest(`.navigation`)
      .querySelector(`.login-item`);
    if (loginContainer.classList.contains(`open`)) {
      closeLogin(loginContainer, loginItem);
    } else {
      openLogin(loginContainer, loginItem);
    }
  }

  function toggleSubmenu(event) {
    const item = event.target
      .closest(`.first-level-item`)
      .querySelector(`.submenu`);
    item.classList.toggle(`open`);
    if (item.classList.contains(`open`)) {
      const height = item.getAttribute(`data-height`);
      item.style.height = `${height}px`;
    } else {
      item.style.height = 0;
    }
  }

  function setSubmenuHeight(module) {
    const sublists = module.querySelectorAll(`.submenu`);
    for (const menu of sublists) {
      const height = menu.offsetHeight;
      menu.setAttribute(`data-height`, height);
      menu.style.height = 0;
    }
  }

  function initMobile(module) {
    const navOpenIcon = module.querySelector(`.open-nav`);
    const navCloseIcon = module.querySelector(`.close`);
    const searchIcon = module.querySelector(`.search`);
    const loginIcon = module.querySelector(`.login`);
    const submenuItems = module.querySelectorAll(`.first-level-item`);
    setSubmenuHeight(module);
    detectTopScoll(module);
    initSearchSubmit(module);

    navOpenIcon.addEventListener(`click`, event => {
      toggleNavigation(event);
    });

    navCloseIcon.addEventListener(`click`, event => {
      toggleNavigation(event);
    });

    searchIcon.addEventListener(`click`, event => {
      toggleSearch(event);
    });

    loginIcon.addEventListener(`click`, event => {
      toggleLogin(event);
    });

    for (const submenu of submenuItems) {
      submenu.addEventListener(`click`, event => {
        if (event.target.closest(`.submenu`) === null) {
          event.preventDefault();
          toggleSubmenu(event);
        }
      });
    }
  }

  for (const module of modules) {
    const desktopNav = module.querySelector(`.desktop`);
    const mobileNav = module.querySelector(`.mobile`);
    initDesktop(desktopNav);
    initMobile(mobileNav);
  }
}
