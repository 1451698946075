export default function() {
  const form = document.querySelectorAll(`.form`);

  function uploadHandler(e, label, labelVal) {
    let fileName = ``;
    if (e.target.files && e.target.files.length > 1) {
      fileName = (e.target.getAttribute(`data-multiple-caption`) || ``).replace(
        `{count}`,
        e.target.files.length
      );
    } else {
      fileName = e.target.value.split(`\\`).pop();
    }

    if (fileName) {
      label.querySelector(`span`).innerHTML = fileName;
      label.querySelector(`.upload-add`).classList.add(`selected`);
    } else {
      label.querySelector(`span`).innerHTML = labelVal;
      label.querySelector(`.upload-add`).classList.remove(`selected`);
    }
  }

  function checkRequirement(elem) {
    const valueElem = elem.querySelector(`select, input, textarea`);
    const errorText = elem.querySelector(`.error-text`);

    if (!valueElem.checkValidity()) {
      valueElem.classList.add(`error`);
      if (errorText) {
        errorText.classList.remove(`hidden`);
      }
      return 1;
    }
    return 0;
  }

  // Fürs recaptcha
  // eslint-disable-next-line no-unused-vars
  function submitHandler(e) {
    e.preventDefault();
    const requiredElements = e.target.querySelectorAll(
      `[data-required="true"]`
    );
    let errorCount = 0;
    requiredElements.forEach(elem => {
      errorCount += checkRequirement(elem);
    });
    if (errorCount !== 0) {
      e.target.querySelector(`.error`).scrollIntoView();
    } else {
      e.target.submit();
    }
  }

  const reqCheck = module => () => {
    const requiredElements = Array.from(
      module.querySelectorAll(`[data-required=true]`)
    );
    const errorCount = requiredElements.reduce(
      (accu, current) => accu + checkRequirement(current),
      0
    );
    if (errorCount !== 0) {
      module.querySelector(`button[type="submit"]`).click(); // Trigger native form Validation
    } else {
      if (module.querySelector(`form`)) {
        module.querySelector(`form`).submit();
      }
      throw new Error(`No Form found`);
    }
  };

  function inputClick(e) {
    e.target.classList.remove(`error`);
    if (e.target.parentElement.querySelector(`.error-text`))
      e.target.parentElement
        .querySelector(`.error-text`)
        .classList.add(`hidden`);
  }

  function init(module) {
    const inputs = module.querySelectorAll(`.upload input`);
    Array.prototype.forEach.call(inputs, input => {
      const label = input.previousElementSibling;
      const labelVal = label.querySelector(`span`).innerHTML;
      input.addEventListener(`change`, e => uploadHandler(e, label, labelVal));
    });
    /*     module
        .querySelector(`form`)
        .addEventListener(`submit`, e => submitHandler(e)); */
    window.validateInputs = reqCheck(module);
    const inputsFields = module.querySelectorAll(`select, input, textarea`);
    inputsFields.forEach(input => {
      input.addEventListener(`click`, e => inputClick(e));
    });
  }

  form.forEach(module => {
    init(module);
  });
}
