export default function() {
  function getCookieValue(a) {
    const b = document.cookie.match(`(^|;)\\s*${a}\\s*=\\s*([^;]+)`);
    return b ? b.pop() : ``;
  }

  function acceptCookies() {
    document.cookie = `cookieBanner=true; expires=Mon, 18 Jan 2038 23:00:00 GMT`;
    window.location.reload();
  }

  function declineCookies() {
    document.querySelector(`.cookie-banner`).classList.add(`hidden`);
  }

  if (getCookieValue(`cookieBanner`) !== `true`) {
    const cookieBanner = document.createElement(`div`);
    const moreInfo = document.createElement(`a`);
    const acceptButton = document.createElement(`button`);
    const declineButton = document.createElement(`button`);
    moreInfo.setAttribute(`href`, `#`);
    moreInfo.innerText = `Mehr erfahren`;
    acceptButton.classList.add(`acceptCookies`);
    acceptButton.innerText = `ZUSTIMMEN`;
    acceptButton.addEventListener(`click`, () => acceptCookies());
    declineButton.classList.add(`declineCookies`);
    declineButton.innerText = `ABLEHNEN`;
    declineButton.addEventListener(`click`, () => declineCookies());
    cookieBanner.classList.add(`cookie-banner`);
    cookieBanner.innerHTML = `Um Ihnen ein besseres Nutzererlebnis zu bieten, verwenden wir Cookies. `;
    cookieBanner.appendChild(moreInfo);
    cookieBanner.appendChild(acceptButton);
    cookieBanner.appendChild(declineButton);
    document.querySelector(`body`).appendChild(cookieBanner);

    const acceptCookieLinks = document.querySelectorAll(`.accept-cookies-link`);

    acceptCookieLinks.forEach(link => {
      link.addEventListener(`click`, e => {
        e.preventDefault();

        acceptCookies();
      });
    });
  }
}
