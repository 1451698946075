/* eslint-disable no-undef */
export const debounce = (func, context, delay) => {
  let inDebounce;
  return args => {
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
    return 1;
  };
};

// Wrap an HTMLElement around another HTMLElement or an array of them.
export function wrapAll(elms, wrapper) {
  const el = elms.length ? elms[0] : elms;
  // Cache the current parent.
  const parent = el.parentNode;
  // Wrap the first element (is automatically removed from its
  // current parent).
  wrapper.appendChild(el);
  // Wrap all other elements (if applicable). Each element is
  // automatically removed from its current parent and from the elms
  // array.
  if (elms.length === undefined) {
    wrapper.appendChild(elms);
  } else {
    for (const element of elms) {
      wrapper.appendChild(element);
    }
  }
  parent.appendChild(wrapper);
}
export const isIterable = obj => {
  const isIE = !!window.MSInputMethodContext && !!document.documentMode;
  if (obj == null) {
    return false;
  }
  if (isIE) {
    return Array.isArray(obj);
  }
  return typeof obj[Symbol.iterator] === `function`;
};

/**
 * @param {HTMLElement[]} target Array oder Element, auf welche(s) die Listener gelegt werden. Wird als this an die Funktion gegeben.
 * @param {Function} enter Mouse Enter function
 * @param {Function} leave Mouse Leave function
 * @param  {...any} args Beliebige Paramaeter
 */
export const hover = (target, enter, leave, ...args) => {
  if (target == null) return;
  const attach = to => {
    if (typeof enter === `function`) {
      to.addEventListener(`mouseenter`, enter.bind(to, ...args));
    }
    if (typeof leave === `function`) {
      to.addEventListener(`mouseleave`, leave.bind(to, ...args));
    }
  };
  if (isIterable(target)) {
    for (const element of target) {
      attach(element);
    }
  } else {
    attach(target);
  }
};

/**
 * @param {string} selector CSS Selector
 * @param {HTMLElement} root Root Element, wenn nichts angegeben "document"
 */
export const ß = (selector, root = document) => {
  let out = [];
  if (selector[0] === `>`) {
    selector = `:scope${selector}`;
  }
  if (Array.isArray(selector)) {
    // TODO: Selector als array
    return undefined;
  }
  out = Array.from(root.querySelectorAll(selector));
  if (out.length === 0) return null;
  return out.length === 1 ? out[0] : out;
};

export const initCustomElementMethods = () => {
  /**
   * Gibt true zurück wenn die (oder ALLE im Falle eines Arrays) Klasse hinzugfügt wurde, false wenn entfernt.
   * @param {string || string[]} className Der zu toggelnde Classenname
   */
  HTMLElement.prototype.toggle = function f(className) {
    const { classList } = this;
    const checkClass = name => {
      if (classList.contains(name)) {
        this.classList.remove(name);
        return false;
      }
      this.classList.add(name);
      return true;
    };
    if (Array.isArray(className)) {
      return className.reduce((accu, current) => {
        if (!checkClass(current)) accu = false;
        return accu;
      }, true);
    }
    if (typeof className === `string`) {
      return checkClass(className);
    }
    return undefined;
  };

  HTMLElement.prototype.siblings = function f() {
    const { parentElement } = this;
    const siblings = [];
    let nextSib = parentElement.firstElementChild;

    while (nextSib) {
      if (nextSib !== this) siblings.push(nextSib);
      nextSib = nextSib.nextElementSibling;
    }

    return siblings;
  };
  HTMLElement.prototype.siblingsAndSelf = function f() {
    const { parentElement } = this;
    const siblings = [];
    let nextSib = parentElement.firstElementChild;

    while (nextSib) {
      nextSib = nextSib.nextElementSibling;
    }

    return siblings;
  };
};

/**
 * Return true if touch Device, false if not
 * Source: bolmaster2 @ https://stackoverflow.com/questions/4817029/whats-the-best-way-to-detect-a-touch-screen-device-using-javascript/4819886#4819886
 */
export const isTouchDevice = () => {
  const prefixes = ` -webkit- -moz- -o- -ms- `.split(` `);
  const mq = query => window.matchMedia(query).matches;

  if (
    `ontouchstart` in window ||
    (window.DocumentTouch && document instanceof window.DocumentTouch)
  ) {
    return true;
  }
  const query = [`(`, prefixes.join(`touch-enabled),(`), `heartz`, `)`].join(
    ``
  );
  return mq(query);
};

export const nodeForEachInit = () => {
  if (`NodeList` in window && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function f(callback, thisArg) {
      thisArg = thisArg || window;
      for (let i = 0; i < this.length; i += 1) {
        callback.call(thisArg, this[i], i, this);
      }
    };
  }
};

export const arrayToLinkedList = array => {
  const out = {};
  const { length } = array;
  for (let index = 0; index < length; index += 1) {
    out[index] = {
      value: array[index],
      prev: index === 0 ? array[length - 1] : array[index - 1],
      next: index === length - 1 ? array[0] : array[index + 1]
    };
  }
  return out;
};
export const spreadSafe = input => {
  if (input == null) return [];
  if (typeof input[Symbol.iterator] === `function`) {
    return input;
  }
  return [input];
};

export const breakpoints = {
  small: () => window.matchMedia(`(min-width: 576px)`).matches,
  medium: () => window.matchMedia(`(min-width: 768px)`).matches,
  large: () => window.matchMedia(`(min-width: 992px)`).matches,
  extralarge: () => window.matchMedia(`(min-width: 1200px)`).matches
};

export function chunk(array, size) {
  const chunkedArr = [];
  let index = 0;
  while (index < array.length) {
    chunkedArr.push(array.slice(index, size + index));
    index += size;
  }
  return chunkedArr;
}

export function closest() {
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.msMatchesSelector ||
      Element.prototype.webkitMatchesSelector;
  }

  if (!Element.prototype.closest) {
    Element.prototype.closest = function f(s) {
      let el = this;

      do {
        if (el.matches(s)) return el;
        el = el.parentElement || el.parentNode;
      } while (el !== null && el.nodeType === 1);
      return null;
    };
  }
}
