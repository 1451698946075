/* eslint-disable class-methods-use-this */
import { h, Component } from "preact";

export default class MiniTGItem extends Component {
  render(props) {
    return (
      <a
        href={props.href}
        className="tile "
        style={`background-image: url(${props.thumbnail}); 
                animation-delay: ${props.animationDelay}ms`}
        data-halfimage={props.half} // Nicht benötigt weil immer 1/3
        data-fullimage={props.full} // Nicht benötigt weil immer 1/3
      >
        <div className="text-box">
          <h3 className={props.teaser ? `caption-small` : `catption-big`}>
            {props.title}
          </h3>
          <p>
            {props.teaser.slice(0, 180)}
            {props.teaser.length > 180 ? `...` : ``}
          </p>
        </div>
      </a>
    );
  }
}
