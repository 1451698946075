import $ from "jquery/dist/jquery";

import dt from "datatables.net"; // eslint-disable-line no-unused-vars

export default function() {
  $(document).ready(() => {
    const modules = document.querySelectorAll(`.module.members`);

    function init(module) {
      const table = module.querySelector(`.table`);
      $(table).DataTable({
        paging: false,
        info: false,
        language: {
          search: `Suche`,
          lengthMenu: `Zeige _MENU_ Einträge`,
          decimal: ``,
          emptyTable: `Keine Daten in dieser Tabelle verfügbar`,
          info: `Einträge _START_ bis _END_ von _TOTAL_ insgesamt`,
          infoEmpty: `Einträge 0 bis 0 von 0 entries`,
          infoFiltered: `(gefiltert von _MAX_ Einträgen insgesamt)`,
          infoPostFix: ``,
          thousands: `.`,
          zeroRecords: `Keine passenden Einträge gefunden`,
          paginate: {
            first: `Erste`,
            last: `Letzte`,
            next: `Nächste`,
            previous: `Vorherige`
          },
          aria: {
            sortAscending: `: Aktivieren um aufsteigend zu sortieren`,
            sortDescending: `: Aktivieren um absteigend zu sortieren`
          }
        }
      });
    }
    for (const module of modules) {
      init(module);
    }
  });
}
