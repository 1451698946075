export default function() {
  const modules = document.querySelectorAll(`div.slider`);
  let xStart;

  function moveSlides(target) {
    const position = target.dataset.index;
    const slides = target.closest(`.slider`).querySelectorAll(`.slide`);
    for (const slide of slides) {
      const translationX = `translateX(-${position * 100}%)`;
      slide.style.transform = translationX;
    }
    target.parentElement.querySelector(`.active`).classList.remove(`active`);
    target.classList.add(`active`);
  }

  function startTimer(number, module) {
    let counter = 1;
    setInterval(() => {
      const position = counter % number;
      const target = module.querySelector(
        `.indicator[data-index="${position}"]`
      );
      moveSlides(target);
      counter += 1;
    }, 10000);
  }

  function handleTouchEnd(xEnd, articles, indicatorGroup) {
    const delta = xStart - xEnd;
    const indicator = indicatorGroup.querySelectorAll(`.indicator`);
    const start = parseInt(
      indicatorGroup.querySelector(`.active`).dataset.index,
      10
    );
    let target = start;
    if (delta > 0) {
      if (start !== indicator.length - 1) {
        target = start + 1;
      }
    } else if (start !== 0) {
      target = start - 1;
    }

    moveSlides(indicator[target]);
  }

  function initSlider(slides, module) {
    const indiGroup = module.querySelector(`.indicator-group`);
    for (const [index, slide] of slides.entries()) {
      slide.setAttribute(`data-index`, index);
      indiGroup.insertAdjacentHTML(
        `beforeend`,
        `<div class="indicator" data-index="${index}"></div>`
      );
    }
    indiGroup.querySelector(`.indicator`).classList.add(`active`);
    const indicators = indiGroup.querySelectorAll(`.indicator`);
    for (const indicator of indicators) {
      indicator.addEventListener(`click`, event => moveSlides(event.target));
    }
    startTimer(slides.length, module);
    module.addEventListener(
      `touchstart`,
      e => {
        xStart = e.changedTouches[0].clientX;
      },
      false
    );

    module.parentElement.addEventListener(
      `touchend`,
      e => {
        const xEnd = e.changedTouches[0].clientX;
        const indicator = module.querySelector(`.indicator-group`);
        handleTouchEnd(xEnd, slides, indicator);
      },
      false
    );
  }

  function init(module) {
    const slides = module.querySelectorAll(`.slide`);
    if (slides.length > 1) {
      initSlider(slides, module);
    }
  }

  for (const module of modules) {
    init(module);
  }
}
