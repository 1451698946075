export default function() {
  const modules = document.querySelectorAll(`.image-gallery`);

  function updateArrows(module) {
    const activeImg = module.querySelector(`.image.active`);
    const nextImg = activeImg.nextElementSibling;
    const prevImg = activeImg.previousElementSibling;
    const leftArrow = module.querySelector(`.arrow.left`);
    const rightArrow = module.querySelector(`.arrow.right`);
    leftArrow.classList.remove(`hidden`);
    rightArrow.classList.remove(`hidden`);
    if (nextImg === null) {
      rightArrow.classList.add(`hidden`);
    }
    if (prevImg === null) {
      leftArrow.classList.add(`hidden`);
    }
  }

  function setImageEvent(image, overlay, module) {
    const img = overlay.querySelector(`img`);
    const p = overlay.querySelector(`p`);
    image.addEventListener(`click`, () => {
      const url = image.querySelector(`img`).getAttribute(`data-fullsize`);
      const desc = image.querySelector(`img`).getAttribute(`data-desc`);
      image.classList.add(`active`);
      img.setAttribute(`src`, url);
      p.innerText = desc;
      overlay.style.display = `block`;
      updateArrows(module);
    });
  }

  function setOverlayEvent(overlay, module) {
    overlay.addEventListener(`click`, event => {
      if (event.target.classList.contains(`image-overlay`)) {
        overlay.style.display = `none`;
        const activeImg = module.querySelector(`.image.active`);
        activeImg.classList.remove(`active`);
      }
    });
  }

  function switchImg(direction, module) {
    const activeImg = module.querySelector(`.image.active`);
    let target = activeImg.previousElementSibling;
    if (direction === `right`) {
      target = activeImg.nextElementSibling;
    }
    const url = target.querySelector(`img`).getAttribute(`data-fullsize`);
    const desc = target.querySelector(`img`).getAttribute(`data-desc`);
    target.classList.add(`active`);
    activeImg.classList.remove(`active`);
    const overlay = module.querySelector(`.image-overlay`);
    const img = overlay.querySelector(`img`);
    const p = overlay.querySelector(`p`);
    img.setAttribute(`src`, url);
    p.innerText = desc;
    updateArrows(module);
  }

  function setArrowEvent(module) {
    const leftArrow = module.querySelector(`.arrow.left`);
    const rightArrow = module.querySelector(`.arrow.right`);
    leftArrow.addEventListener(`click`, () => {
      switchImg(`left`, module);
    });
    rightArrow.addEventListener(`click`, () => {
      switchImg(`right`, module);
    });
  }

  function init(module) {
    const images = module.querySelectorAll(`.image`);
    const overlay = module.querySelector(`.image-overlay`);
    for (const image of images) {
      setImageEvent(image, overlay, module);
    }
    setOverlayEvent(overlay, module);
    setArrowEvent(module);
  }

  for (const module of modules) {
    init(module);
  }
}
