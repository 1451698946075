/* eslint-disable class-methods-use-this */
// eslint-disable-next-line no-unused-vars
import preact, { h, render, Component } from "preact";
import Search from "./Search";
import MediumTGItem from "./Components/search/MediumTGItem";
import MiniTGItem from "./Components/search/MiniTGItem";
import FilterList from "./Components/search/FilterList";
import { chunk } from "./utils";

export default function() {
  const block = document.querySelector(`.search-results`);
  window.idTagMapping = new Map(); // key: Tag; value: set of IDs

  class SearchResults extends Component {
    constructor(props) {
      super();
      const { module } = props;
      const isSplitView = module.closest(`.split`);
      this.ref = preact.createRef();
      this.state = {
        isSplitView,
        module,
        visualMode: `medium-tile-grid`,
        observers: [],
        position: 0,
        visibleResults: [],
        results: []
      };
      window.search = new Search();
    }

    componentWillMount() {
      const { module } = this.state;
      const visualMode = module.dataset.visualMode || `medium-tile-grid`;
      const fallback = {
        universal: JSON.parse(module.dataset.fallbackUniversal),
        job: JSON.parse(module.dataset.fallbackJob),
        contact: JSON.parse(module.dataset.fallbackContact)
      };
      let thumbnailKey;
      switch (visualMode) {
        case `medium-tile-grid`:
          thumbnailKey = this.state.isSplitView
            ? `item-1-3-search`
            : `item-1-4-text`;
          break;
        case `mini-tile-grid`:
          thumbnailKey = `mini-tile-grid`;
          break;
        default:
          thumbnailKey = ``;
      }
      this.setState({
        ...this.state,
        visualMode,
        fallback,
        thumbnailKey
      });

      // change to execute query and clear
      window.search.subscribeToResults(this.resultHandler(this));
      window.search.subscribeToClear(this.clear);
    }

    clear = () => {
      this.setState({
        ...this.state,
        visibleResults: []
      });
    };

    componentDidUpdate() {
      const element = this.state.module.querySelector(`.result-delimiter`);
      if (!element.dataset.isWatched) {
        const observer = new window.IntersectionObserver(entires => {
          if (entires[0].isIntersecting) {
            this.addVisibleItemsFrom(this.state.results);
            if (
              this.state.results.length - this.state.visibleResults.length <
              12
            ) {
              window.search.addToQueue(60);
            }
          }
        });
        element.dataset.isWatched = true;
        observer.observe(element);
      }
    }

    resultHandler = () => items => {
      const itemsWithFallbackImages = items.map(this.setImage);
      this.setState({
        ...this.state,
        results: itemsWithFallbackImages
      });
      const addToVisible =
        !this.state.visibleResults.length ||
        this.state.module
          .querySelector(`.result-delimiter`)
          .getBoundingClientRect().top < window.innerHeight;
      if (addToVisible) {
        this.addVisibleItemsFrom(this.state.results);
      }
    };

    setImage = item => {
      const { type, thumbnail } = item._source;
      if (thumbnail[this.state.thumbnailKey] !== undefined) {
        item._source.image = thumbnail[this.state.thumbnailKey];
      } else {
        const fallback = this.getFallbackForType(type);
        item._source.image = fallback;
      }
      return item;
    };

    getFallbackForType = (type = `universal`) =>
      this.state.fallback[type]
        ? this.state.fallback[type][this.state.thumbnailKey]
        : this.state.fallback.universal[this.state.thumbnailKey];

    addVisibleItemsFrom = results => {
      const loadMoreEnd = this.state.visibleResults.length + 12;
      const visibleResults = results.slice(0, loadMoreEnd);
      this.setState({
        ...this.state,
        visibleResults
      });
    };

    render(props, state) {
      if (!state.visibleResults.length)
        return (
          <section className="module-content">
            <span className="warn"> Diese Suche hat keine Ergebnisse.</span>
            <div className="result-delimiter" />
          </section>
        );
      if (state.visualMode === `medium-tile-grid`) {
        return (
          <section className="module-content">
            <noscript>Please enable Javascript</noscript>
            <div className="results mtg-grid">
              {state.visibleResults.map(({ _source: item }) => (
                <MediumTGItem
                  title={item.title}
                  teaser={item.teaser}
                  introtext={item.teaser}
                  thumbnail={item.image}
                  href={item.link}
                  animationDelay={0}
                />
              ))}
            </div>
            <div className="result-delimiter" />
          </section>
        );
      }
      if (state.visualMode === `mini-tile-grid`) {
        const chunks = chunk(state.visibleResults, 3);
        return (
          <section className="module-content">
            <noscript>Please enable Javascript</noscript>
            <div className="results content">
              {chunks.map(ch => (
                <div className="tile-wrap small">
                  {ch.map(({ _source: item }) => (
                    <MiniTGItem
                      title={item.title}
                      teaser={item.teaser}
                      thumbnail={item.image}
                      href={item.link}
                      animationDelay={0}
                    />
                  ))}
                </div>
              ))}
            </div>
            <div className="result-delimiter" />
          </section>
        );
      }
      return (
        <section className="module-content">
          <noscript>Please enable Javascript</noscript>
          <div className="results content">
            <FilterList
              items={state.visibleResults.reduce((accu, { _source: item }) => {
                if (item.title !== ``) {
                  return [
                    ...accu,
                    {
                      subhead: item.title,
                      text: item.teaser,
                      link: item.link
                    }
                  ];
                }
                return accu;
              }, [])}
            />
          </div>
          <div className="result-delimiter" />
        </section>
      );
    }
  }

  async function init(module) {
    render(<SearchResults module={module} />, module);
  }

  if (block) {
    init(block);
  }
}
