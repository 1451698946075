/* eslint-disable class-methods-use-this */
import { h } from "preact";

export default function FilterList(props) {
  const { items, head } = props;
  return (
    <div class="module list">
      <div class="sle-overview">
        {head && <h4>{head}</h4>}

        {items.map(item => (
          <div class="item">
            <div class="SLE">
              <h2 class="headline-list">
                <a href={item.link}>{item.subhead}</a>
              </h2>
              <span class="abstract">{item.text}</span>
              <br />
              <a href={item.link}>
                <button class="link-button">
                  mehr
                  <i class="fas fa-arrow-right" />
                </button>
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
