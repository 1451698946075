export default class Search {
  constructor() {
    const searchLocation = window.location.search;
    const { query, tags } = Search.readSearch(searchLocation);
    this.query = query;
    this.activeTags = new Set(tags);
    this.api = `/search`;
    this.base = window.location.host.includes(`localhost`)
      ? `//group.dev.local`
      : ``;
    this.language = window.language || `de`;

    this.tagSubscribers = [];
    this.availableTagSubscribers = [];
    this.querySubscribers = [];
    this.availableTags = this.activeTags;
    this.resultSubscribers = [];
    this.results = [];
    this.clearSubscribers = [];
    this.position = 0;
    this.size = 12;

    this.loadQueue = []; // Array of sizes (how many you want to laod)
    this.loading = false;

    this.informQuerySubscribers();
    window.addEventListener(`popstate`, () => {
      window.location.href = document.referrer;
    });
  }

  static readSearch(search) {
    const reg = new RegExp(
      /\?(query=(.*?))?&?(tags=(.*?))?&?(company=(.*?))?;$/
    );
    const match = search.match(reg);
    const { 2: query, 4: activeTags } = match || { 2: ``, 4: `` };
    const tags = decodeURIComponent(activeTags).split(`;`);
    return {
      query,
      tags
    };
  }

  setQuery(query) {
    if (this.query === query) return;
    if (query === `` || typeof query !== `string`) {
      this.query = `*`;
    } else {
      this.query = query;
    }
    this.informQuerySubscribers();
    this.executeSearch();
  }

  addTags(array) {
    let didChange = false;
    for (const tag of array) {
      if (!this.activeTags.has(tag)) {
        this.activeTags.add(tag);
        didChange = true;
      }
    }
    if (didChange) {
      this.informTagSubscribers();
      this.executeSearch();
    }
  }

  removeTags(array) {
    let didChange = false;
    for (const tag of array) {
      if (!this.activeTags.has(tag)) {
        this.activeTags.delete(tag);
        didChange = true;
      }
    }
    if (didChange) {
      this.informTagSubscribers();
      this.executeSearch();
    }
  }

  clearTags() {
    this.activeTags.clear();
    this.informTagSubscribers();
    this.executeSearch();
  }

  addAvailableTags(array) {
    for (const tag of array) {
      this.availableTags.add(tag);
    }
  }

  removeAvailableTags(array) {
    for (const tag of array) {
      this.availableTags.delete(tag);
    }
  }

  clearAvailableTags() {
    this.availableTags.clear();
  }

  clearCurrentState() {
    this.loadQue = [12, 60];
    this.position = 0;
    this.results = [];
    this.availableTags = this.activeTags;
  }

  // Execute Search starts a new search at position zero , clears the previous results.
  executeSearch() {
    // Clear Previous Que:
    this.clearCurrentState();
    window.history.pushState(`any`, this.query, this.buildSearchString());
    this.informClearSubscribers();
    this.workQueue();
  }

  workQueue() {
    if (this.loadQue.length) {
      this.loading = true;
      const nextSize = this.loadQue.shift();
      this.loadNextItems(nextSize).then(() => {
        this.workQueue();
      });
    } else {
      this.loading = false;
    }
  }

  addToQueue(size) {
    this.loadQue.push(size);
    if (!this.loading) {
      this.workQueue();
    }
  }

  buildSearchString() {
    return `?query=${this.query}&tags=${Array.from(this.activeTags).join(
      `;`
    )}&company=;`;
  }

  static buildJsonQuery(query, tags, company, position, size, language) {
    return JSON.stringify({
      query,
      tags: Array.from(tags),
      company,
      from: position,
      size,
      language
    });
  }

  subscribeToTags(fun) {
    if (typeof fun === `function`) {
      this.tagSubscribers.push(fun);
      fun(this.activeTags);
    } else {
      throw new Error(`Thats not a function!`);
    }
  }

  informTagSubscribers() {
    for (const fun of this.tagSubscribers) {
      fun(this.activeTags);
    }
  }

  subscribeToAvailableTags(fun) {
    if (typeof fun === `function`) {
      this.availableTagSubscribers.push(fun);
      fun(this.availableTags);
    } else {
      throw new Error(`Thats not a function!`);
    }
  }

  informAvailableTagSubscribers() {
    for (const fun of this.availableTagSubscribers) {
      fun(this.availableTags);
    }
  }

  subscribeToQuery(fun) {
    if (typeof fun === `function`) {
      this.querySubscribers.push(fun);
      return this.query;
    }
    throw new Error(`Thats not a function!`);
  }

  informQuerySubscribers() {
    for (const fun of this.querySubscribers) {
      fun(this.query);
    }
  }

  async loadResults() {
    const {
      base,
      api,
      position,
      size,
      query,
      activeTags,
      company,
      language
    } = this;
    const response = await window.fetch(`${base}${api}`, {
      method: `POST`,
      body: Search.buildJsonQuery(
        query,
        activeTags,
        company,
        position,
        size,
        language
      )
    });
    let data;
    try {
      data = await response.json();
    } catch (error) {
      throw error;
    }
    if (data == null) throw new Error(`no data`);
    const hits = data.hits.map(item => {
      const thumbnails = {};
      if (item._source.thumbnail) {
        const json = JSON.parse(item._source.thumbnail);
        for (const key of Object.keys(json)) {
          thumbnails[key] = `${base}${json[key]}`;
        }
      }
      return {
        ...item,
        _source: {
          ...item._source,
          thumbnail: thumbnails
        }
      };
    });
    this.position = position + size;
    this.results = [...this.results, ...hits];
    this.informResultSubscribers(this.results);
  }

  async loadNextItems(count = this.size) {
    return new Promise(resolve => {
      const defaultSize = this.size;
      this.size = count;
      this.loadResults().then(() => {
        resolve(this.results);
        this.size = defaultSize;
        this.collectAvailableTags();
      });
    });
  }

  subscribeToResults(fun) {
    if (typeof fun === `function`) {
      this.resultSubscribers.push(fun);
    } else {
      throw new Error(`Thats not a function!`);
    }
  }

  // Sends a Generator to the Subscriber, from which he can get the items in blocks of 16;
  informResultSubscribers(items) {
    for (const fun of this.resultSubscribers) {
      fun(items);
    }
  }

  collectAvailableTags() {
    const items = this.results.flatMap(({ _source }) => _source.tags);
    this.availableTags = items.reduce((accu, item) => {
      const splitItem = item.split(`/`).filter(a => a !== ``);
      if (accu[splitItem[0]] == null) accu[splitItem[0]] = [];
      accu[splitItem[0]] = [
        ...accu[splitItem[0]],
        new Map([
          [`root`, splitItem[0]],
          [`name`, splitItem[splitItem.length - 1]],
          [`full`, item]
        ])
      ];
      return accu;
    }, {});
    this.informAvailableTagSubscribers();
  }

  subscribeToClear(fun) {
    if (typeof fun === `function`) {
      this.clearSubscribers.push(fun);
    } else {
      throw new Error(`Thats not a function!`);
    }
  }

  informClearSubscribers() {
    for (const fun of this.clearSubscribers) {
      fun();
    }
  }
}
